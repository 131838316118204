<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3337 5.59082L15.242 7.49915L11.1753 11.5658L7.84199 8.23249L1.66699 14.4158L2.84199 15.5908L7.84199 10.5908L11.1753 13.9242L16.4253 8.68249L18.3337 10.5908V5.59082H13.3337Z"
      fill="#1A8CFF"
    />
    <path
      d="M2.58919 10.1903L3.00586 8.52364L4.67253 8.10697L3.00586 7.69031L2.58919 6.02364L2.17253 7.69031L0.505859 8.10697L2.17253 8.52364L2.58919 10.1903Z"
      fill="#1A8CFF"
    />
    <path
      d="M9.22461 6.82452L10.0079 5.09952L11.7246 4.32452L10.0079 3.54952L9.22461 1.82452L8.45794 3.54952L6.72461 4.32452L8.45794 5.09952L9.22461 6.82452Z"
      fill="#1A8CFF"
    />
  </svg>
</template>

<script>
export default {
  name: 'IcPMax',
  props: {
    size: {
      type: [String, Number],
      default: 16,
    },
    value: {
      type: String,
      default: 'default',
    },
  },
}
</script>

<style scoped></style>
